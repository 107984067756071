<template>
	<div class="schedule">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>中古車販売買取</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									中古車販売買取
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div class="block section-padding-50">
			<div class="page container">
				<div class="row mb-5">
					<div class="col-12">
						<h3>中古車販売</h3>
						<ul class="points" style="background-color: #fff8e2">
							<li>
								販売車両は買取や下取りでコンディションの良い車両のみを厳選しております。
							</li>
							<li>
								WEB掲載していない委託の販売車両もございますのでお問合わせください。
							</li>
							<li>
								整備メインのお店になりますので車両のリフレッシュは充実しております。
							</li>
							<li>ご予算に応じてリフレッシュプランをご提案します。</li>
							<li>
								ご購入後のアフターサービスはASSISTの特典はすべて付加され優遇されます。
							</li>
						</ul>

						<p class="text-center mt-5">
							<a
								href="https://store.assist-kyoto.co.jp/collections/cars"
								target="_blank"
								class="btn contact-btn mx-5 mb-3"
								style="min-width: 300px"
								>中古車在庫リスト<span class="badge badge-dark ml-1"
									>OFFICAL</span
								><br />
								<small style="font-weight: bold">（ASSIST-STOREページ）</small>
							</a>
							<a
								href="https://www.carsensor.net/shop/kyoto/100662002/stocklist/"
								target="_blank"
								class="btn contact-btn mx-5 mb-3"
								style="min-width: 300px"
								>中古車在庫リスト<br />
								<small>（カーセンサーページ）</small></a
							>
						</p>
					</div>
				</div>

				<div class="row mb-5">
					<div class="col-12">
						<h3>予約販売</h3>

						<h4>■ 特徴</h4>
						<ul class="points">
							<li>探す時間や移動コストが節約されます。</li>
							<li>
								車両下見を１００％委託することでコンディションや傷を見落とすリスクが減ります。
							</li>
						</ul>
						<p>
							予約販売は高年式や走行距離が少ない等コンディションの良好な車をお探しの方に特にお薦めです。
							ご予算とコンディションレベルを打合わせの上で探しますのでグレードや色などの仕様を決めて頂くだけでクルマの目利きが不要です。<b>予約は無料</b>になりますのでお気軽にお申し付けください。
						</p>

						<h4>■ スケジュール</h4>
						<ol>
							<li>
								当店で用意しております予約販売シートにご要望をお伺いしながら車両とコンディションレベルを決定します。
							</li>
							<li>
								要望通りの車が出てくれば詳細をメールでご連絡します。（画像付き）
							</li>
							<li>
								ご購入希望の車両について"Goサイン"を出して頂きご予算内で収まる範囲で仕入れることができれば入庫となります。
							</li>
							<li>入庫後に実車確認して頂きご契約となります。</li>
							<li>
								登録手続き中に車両はリフレッシュやカスタマイズされます。登録完了とともにご納車となります。
							</li>
						</ol>
					</div>
				</div>

				<div class="row mb-5">
					<div class="col-12">
						<h3>新車販売</h3>
						<div class="outline">
							<p>
								<b>当店でも新車購入が出来ます！</b
								>クルマの窓口が1本化されることでメリットがたくさんあります。
							</p>
							<ul class="points">
								<li>
									ディーラーで購入する場合に加えて当店のサポートがオンされます。
								</li>
								<li>
									メーカー保証はディーラと同様に適用されます。（全国どこのディーラーでもメーカー保証を受けることが出来ます）
								</li>
								<li>
									ディーラーのクレジットやリースを利用することが出来ます。
								</li>
								<li>
									4輪アライメント調整などASSISTの特典はすべて付加されます。
								</li>
								<li>
									パーツ装着やチューニングをご検討されている方はカスタマイズされた状態でご納車します。
								</li>
							</ul>
							<p>お見積をご希望される場合はお気軽にお申し付けください。</p>
							<h6>
								<b>新車同様の高年式ユーズド車は価格に魅力！！</b><br />
								見た目は新車との区別が付かないコンディションで高年式車はメーカー保証も長くご愛用すると新車とほとんど遜色が無いのに価格は大きくプライスダウンします。日本は新車の価値観が高いため機能面で差が無くても新車か中古車かで価値の差が大きいので下落率が大きいためコストパフォーマンスが非常に良いです！
							</h6>

							<p class="text-center mt-5">
								<a
									href="https://form.run/@assist-kyoto"
									target="_blank"
									class="btn contact-btn"
									>お問い合わせはこちら<br />
									<small>お電話（075-582-7788）でもお気軽に</small></a
								>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
export default {
	title: "中古車販売買取",
	components: {},
};
</script>

<style scoped lang="scss">
p {
	color: #000;
}
b {
	color: #1976d2;
}
h4 {
	font-size: 1rem;
}
h6 {
	font-size: 0.9rem;
	font-weight: normal;
	line-height: 1.8rem;
	position: relative;
	padding: 1.2rem;
	background-color: #f3f5f8;
	&:before {
		content: "";
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		left: -15px;
		top: 20px;
		border-right: 15px solid #f3f5f8;
		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
	}
}
ul.points,
ol.points {
	border: 2px solid #000;
	padding: 1rem;
}

.ec-btn {
	display: inline-block;
	min-width: 150px;
	color: #fff !important;
	border: none;
	border-radius: 5px;
	line-height: 1.6rem;
	padding: 1rem 2rem;
	font-size: 1rem;
	font-weight: 700 !important;
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	text-transform: uppercase;
	background: #f55555;
	background: -webkit-linear-gradient(to right, #f55555, #fccf31);
	background: linear-gradient(to right, #f55555, #fccf31);
}
.ec-btn.active,
.ec-btn:hover,
.ec-btn:focus {
	color: #2c3e50 !important;
}

.ec-btn2 {
	display: inline-block;
	min-width: 150px;
	color: #fff !important;
	border: none;
	border-radius: 5px;
	line-height: 1.6rem;
	padding: 1rem 2rem;
	font-size: 1rem;
	font-weight: 700 !important;
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	text-transform: uppercase;
	background: #b84747;
	background: -webkit-linear-gradient(to right, #b84747, #c2a024);
	background: linear-gradient(to right, #b84747, #c2a024);
}
.ec-btn2.active,
.ec-btn2:hover,
.ec-btn2:focus {
	color: #2c3e50 !important;
}
</style>
